import React from "react";
import { Trans, useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import BecomeMemberIcon from "../../../static/icons/becomeAMemberIcon.svg";
import BookMeetingIcon from "../../../static/icons/bookMeetingIcon.svg";
import ContactRequestIcon from "../../../static/icons/sendContactRequestIcon.svg";
import StartWorkingIcon from "../../../static/icons/startWorkingWithPartnersIcon.svg";
import UnlockSecretsIcon from "../../../static/icons/unlockSecretsIcon.svg";
import WeSearchIcon from "../../../static/icons/weWillSearchIcon.svg";
import Card from "../../components/Card";
import { getTranslationList } from "../../utils/common";

const cardsStyle = css`
  > div {
    ${tw`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-5xl pt-8`}
  }
  > h2 {
    ${tw`text-5xl text-center font-black`}
  }
`;

const HowItWorks: React.FC = () => {
  const { t } = useTranslation();
  const cards = getTranslationList(t, "howItWorks.cardSection.cards.header");
  const icons = [
    <BookMeetingIcon key="BookMeetingIcon" />,
    <UnlockSecretsIcon key="UnlockSecretsIcon" />,
    <BecomeMemberIcon key="BecomeMemberIcon" />,
    <WeSearchIcon key="WeSearchIcon" />,
    <ContactRequestIcon key="ContactRequestIcon" />,
    <StartWorkingIcon key="StartWorkingIcon" />
  ];

  return (
    <div css={cardsStyle}>
      <h2>{t("howItWorks.header")}</h2>
      <div>
        {cards.map((item, i) => (
          <Card icon={icons[i]} key={i}>
            <Trans i18nKey={t(item)} components={{ bold: <strong />, br: <br /> }} />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
